import { defineStore } from 'pinia'
import PocketBase from "pocketbase"

export const pb = new PocketBase('https://em.up.railway.app');

export const useGlobalStore = defineStore('global', {
  state: () => ({
    isPageLoading: false,
    modalOpened: false,
    infoPages: [],
  }),
  getters: {},
  actions: {
    startLoading() {
      this.isPageLoading = true;
    },
    endLoading() {
      this.isPageLoading = false;
    },
    setPageLoading(status) {
      this.isPageLoading = status;
    },
    async getInfoPages() {
      this.setPageLoading(true);
      const infoPages = await pb.collection('INFO_OLDALAK').getList(1, 20, {
        filter: 'aktiv = true',
      });
      console.log({infoPages});
      this.infoPages = infoPages.items;
      this.setPageLoading(false);
    },
  }
})
export const useProductsStore = defineStore('products', {
  state: () => ({
    products: [],
    product: {},
    productCategories: [],
    order: {},
    retry: 0,
  }),
  getters: {
    productsAll: (state) => state.products.filter(() => true),
    getCategoryById: (state) => (catId) => state.productCategories.find(cat => cat.id === catId),
  },
  actions: {
    async getProducts(categoryId) {
      const globalStore = useGlobalStore();
      globalStore.setPageLoading(true);
      console.info({categoryId});
      try {
        const products = await pb.collection('TERMEKEK').getList(1, 100, {
            filter: `Aktiv = true && Fokategoria ~ "${categoryId || ''}"`,
            fields: 'id, Azonosito, Fokategoria, Kep, Ar_mutat, Alap_ar, Akcios_ar, Tol_ar, Ig_ar, Nev, Fo_tulajdonsag, Telepitve, Teljesitmeny, collectionId',
            sort:   '-Sorrend, Teljesitmeny'
        });
        console.log({products});
        this.products = products.items;
        globalStore.setPageLoading(false);
      } catch (error) {
        console.log('Error:', error);
      }
    },
    async getCategories() {
      try {
        const globalStore = useGlobalStore();
        globalStore.setPageLoading(true);
        const categories = await pb.collection('KATEGORIAK').getList(1, 20, {
          filter: 'Aktiv = true'
        });
        console.log({categories});
        this.productCategories = categories.items;
        globalStore.setPageLoading(false);
      } catch (error) {
        console.log('Error:', error);
      }
    },
    async getProductById(prodId) {
      const globalStore = useGlobalStore();
      globalStore.setPageLoading(true);
      this.product = { loading: true };
      try {
        const product = await pb.collection('TERMEKEK').getOne(prodId);
        /* const product = await pb.collection('TERMEKEK').getList(1, 1, {
            filter: `Aktiv = true && uid = ${prodId}`,
        }); */
        console.log({product});
        if (!product.Aktiv) {
          product.Alap_ar = product.Akcios_ar = product.Tol_ar = product.Ig_ar = 0;
        }
        this.product = product;
        globalStore.setPageLoading(false);
        /* this.product = product.items[0]; */
      } catch (error) {
        console.log('Error:', error);
      }
    },
  }
})

export const useTextsStore = defineStore('texts', {
  state: () => ({
    texts: {},
  }),
  actions: {
    async getPageTexts() {
      const globalStore = useGlobalStore();
      globalStore.setPageLoading(true);
      this.texts = { loading: true };
      try {
        const texts = await pb.collection('SZOVEGEK').getList(1, 20, {
          filter: 'aktiv = true',
        });
        console.log({texts});
        for (const i in texts.items) {
          const text = texts.items[i];
          texts[text.azonosito] = text.szoveg;
        }
        this.texts = texts;
        globalStore.setPageLoading(false);
      } catch (error) {
        console.log('Error:', error);
      }
    },
  }
})

export const useBlogStore = defineStore('blog', {
  state: () => ({
    posts: {},
    post: null,
  }),
  actions: {
    async getBlogPosts() {
      const globalStore = useGlobalStore();
      globalStore.setPageLoading(true);
      const posts = await pb.collection('BLOG').getList(1, 20, {
        filter: 'Aktiv = true',
      });
      console.log({posts});
      this.posts = posts.items;
      globalStore.setPageLoading(false);
    }
  },

})

export const useCalculatorsStore = defineStore('calculators', {
  state: () => ({
    calculators: {},
  }),
  actions: {
    async getCalculators() {
      const globalStore = useGlobalStore();
      globalStore.setPageLoading(true);
      this.calculators = { loading: true };
      try {
        const calculators = await pb.collection('KALKULATOROK').getList(1, 20, {
          filter: 'aktiv = true',
          sort: '-created',
        });
        console.log({calculators});
        this.calculators = calculators.items;
        globalStore.setPageLoading(false);
      } catch (error) {
        console.log('Error:', error);
      }
    },
  }
})