<template>
	<div
		class="InputField"
		:class="{ 'has-error': !!errorMessage, success: meta.valid && meta.touched }"
		v-if="['text', 'tel', 'email'].includes(type)"
	>
		<label :for="name">{{ label }}</label>
		<input
			:name="name"
			:id="name"
			:type="type"
			:value="inputValue"
			:placeholder="placeholder"
			@blur="handleBlur"
			@change="handleChange"
			v-bind="extra"
		/>
		<p class="help-message" v-show="errorMessage">
			{{ errorMessage }}
		</p>
	</div>

	<div
		class="InputField"
		:class="{ 'has-error': !!errorMessage, success: meta.valid && meta.touched }"
		v-if="type === 'textarea'"
	>
		<label :for="name">{{ label }}</label>
		<textarea
			:name="name"
			:id="name"
			:type="type"
			:value="inputValue"
			:placeholder="placeholder"
			rows="8"
			@blur="handleBlur"
			@change="handleChange"
		/>
		<p class="help-message" v-show="errorMessage">
			{{ errorMessage }}
		</p>
	</div>

	<div 
		class="CheckBox InputField"
		:class="{ 'has-error': !!errorMessage, success: meta.valid && meta.touched }"
		v-else-if="type === 'checkbox'"
	>
		<div class="flex items-center">
			<input 
				:name="name"
				:id="name"
				type="checkbox"
				:value="inputValue"
				@change="handleChange"
				:checked="checked"
			>
			<label :for="name">
				<slot/>
			</label>
		</div>


		<p class="help-message" v-show="errorMessage">
			{{ errorMessage }}
		</p>
	</div>

	<div 
		class="InputField"
		:class="{ 'has-error': !!errorMessage, success: meta.valid && meta.touched }"	
		v-else-if="type === 'radio'"
	>
		<legend class="text-base font-medium text-gray-900">
			{{ label }}
		</legend>
		<p class="text-sm text-gray-500">
			<div class="help-message" v-show="errorMessage"> {{ errorMessage }} </div> 
			<span v-if="!props.noinfo">Kattintson a megfelelő válaszra</span>
		</p>

		<div class="radio-check mt-2">
			<template v-for="option in options" :key="option.value || option.label">
				<input
					:type="type"
					:name="name"
					:id="name + '_' + (option.value || option.label).toLowerCase()"
					:value="(option.value || option.label).toLowerCase()"
					@change="handleChange"
				/>
				
				<label
					:for="name + '_' + (option.value || option.label).toLowerCase()"
					:class="['text-sm font-medium text-gray-700', !!errorMessage ? 'has-error' : '']"
				>
					{{ option.label }}
				</label>
				
			</template>
		</div>
	</div>

</template>

<script setup>
import { useField } from 'vee-validate';

const props = defineProps({
	type: {
		type: String,
		default: 'text',
	},
	value: {
		type: null,
		default: undefined,
	},
	modelValue: {
		type: null,
	},
	name: {
		type: String,
		required: true,
	},
	label: {
		type: String,
		default: null,
	},
	placeholder: {
		type: String,
		default: '',
	},
	options:	{
		type: Array,
		default: [],
	},
	noinfo: {
		type: Boolean,
		default: false,
	},
	extra: {
		type: Object,
		default() {
			return {};
		},
	},
});

const { name } = toRefs(props);
const type = props.type;

const {
	value: inputValue,
	errorMessage,
	handleChange,
	handleBlur,
	checked,
	meta,
} = useField(name, undefined, {
	type: props.type,
	initialValue: (type !== 'checkbox') ? ((props.modelValue || props.value) || undefined) : false,
	checkedValue: (type === 'checkbox') ? (props.modelValue || props.value) : null,
	uncheckedValue: false,
});

</script>

<style>
:root {
	--primary-color: #0071fe;
	--error-color: #f23648;
	--error-bg-color: #fddfe2;
	--success-color: #21a67a;
	--success-bg-color: #e0eee4;
	--success-color: #00764f;
	--success-bg-color: #e0ede4;
}
label a {
	text-decoration: underline;
}
</style>

<style scoped lang="scss">
label {
	display: block;
	margin-bottom: 4px;
	width: 100%;
}
.flex {
	display: flex;
}
.items-center {
	align-items: center;
}

.InputField,
.CheckBox {
	position: relative;
	margin-bottom: calc(1em * 1.5);
	width: 100%;
	max-width: 440px;
	
	@media (max-width: 730px) {
		width: 60%;
	}
	@media (max-width: 600px) {
		width: 95%;
	}
	
	input {
		margin-right: 8px;
	}
}

.CheckBox {
	label {
		margin: 0;
	}
}

textarea {
	padding-top: 5px !important;
}

input:not([type = 'radio']):not([type = 'checkbox']),
textarea {
	border-radius: 5px;
	border: 2px solid transparent;
	padding: 12px 10px;
	padding: 8px 10px;
	outline: none;
	background-color: #f2f5f7;
	width: 100%;
	transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
	background-color 0.3s ease-in-out;
}

input:focus,
textarea:focus {
	border-color: var(--primary-color);
}

input[type = 'checkbox'] {
    height: 1rem;
    width: 1rem;
    border-radius: 0.25rem;
    border-color: var(--main-color);
	 border-width: 1px;
	 border-style: solid;
    color: var(--main-color);
}

.help-message {
	/* position: absolute;
	bottom: calc(-1.5 * 1em); */
	margin: 0;
	font-size: 14px;
}

.InputField.has-error input,
.InputField.has-error textarea {
	background-color: var(--error-bg-color);
	color: var(--error-color);
}

.InputField.has-error input:focus,
.InputField.has-error textarea:focus{
	border-color: var(--error-color);
}

.InputField.has-error .help-message {
	color: var(--error-color) !important;
}

.InputField.success input:not([type = 'checkbox']),
.InputField.success textarea {
	background-color: var(--success-bg-color);
	color: var(--success-color);
}

.InputField.success input:focus,
.InputField.success textarea:focus{
	border-color: var(--success-color);
}

.InputField.success .help-message {
	color: var(--success-color);
}

input.has-error,
label.has-error,
textarea.has-error {
	background-color: #fddfe2;
	border-color: transparent;
	color: #f23648;
}

.error-msg {
	color: #f23648;
}

	.radio-check {
		display: flex;
		flex-flow: row wrap;
		gap: 0.4rem 1.2rem;

		label {
			flex: 1;
			margin:0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			border: 1px solid var(--main-color);
			padding: 10px;
			margin-bottom: 1rem;
			text-align: center;
			box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
			position: relative;
			transition: 0.3s;
			font-size: 15px;
			border-radius: 10px;
		}
	}

	input[type="radio"] {
		display: none;
	}
	input[type="radio"] ~ label {
		cursor: pointer;
		line-height: 1rem;
		min-width: 100px;
	}
	input[type="radio"]:checked + label,
	input[type="checkbox2"]:checked + label {
		background: rgb(var(--main-color-rgb) / 85%);
		color: white;
		box-shadow: 0px 0px -10px 10px rgba(247, 144, 70, 0.5);
	}
</style>
