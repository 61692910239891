<template>
  <div class="breadcrumbs" v-if="route.name !== 'Home'">
		<RouterLink :to="{ name: 'Home' }">
			<i-carbon-home/>
		</RouterLink>
		<template v-for="(crumb, i) in crumbs" :key="i">
			<RouterLink :to="crumb.to" v-if="crumb.crumb !== route.params.uid && crumb.crumb !== route.params.postId">
				{{ translation[crumb.crumb] || crumb.crumb.replace(/-/g, ' ')}}
			</RouterLink>
		</template>
  </div>
</template>

<script setup>
	import { useRoute } from 'vue-router';
	/* import { storeToRefs } from 'pinia';
	import { useProductsStore } from '../stores'; */
	
	const route = useRoute();
	/* TODO:
		Lehet meg lehetne ezt oldani vue-routerral és használni a meta részt
	 */
	let locationPath = window.location.pathname.substring(1).split('/');

	/* const productsStore = useProductsStore();
	const { product } = storeToRefs(productsStore); */

	const createCrumbs = () => 
		locationPath.flatMap((crumb, i) => {
			/* const result1 = terms.some(term => str.includes(term)) */
			if (crumb.includes("prod_"))
				return [];
			return {
				crumb,
				to: `/${locationPath.slice(0, i + 1).join("/")}`
			}
		});

	const crumbs = ref(createCrumbs());
	watch(() => route.fullPath, (newValue, oldValue) => {
		locationPath = window.location.pathname.substring(1).split('/');
		crumbs.value = createCrumbs();
	});

	const translation = {
		'Home': 'Főoldal',
		'termekek': 'Termékek',
		'erdeklodes': 'Érdeklődés',
		'adatkezelesi-tajekoztato': 'Adatkezelési tájékoztató',
		'kalkulatorok': 'Kalkulátorok',
		'eddigi-munkaink': 'Eddigi Munkáink',
		'rolunk': 'Rólunk',
		'ajanlatot-kerek': 'Ajánlatot Kérek',
		/* categories */
		'klimak': 'Klímák',
		'hoszivattyuk': 'Hőszivattyuk',
		'futesi-csomagok': 'Fűtési csomagok',
		'hutesi-csomagok': 'Hűtési csomagok',
		'kulcsrakesz-napelemes-rendszerek': 'Kulcsrakész napelemes rendszerek',
		'napelemes-rendszer-cegeknek': 'Napelemes rendszerek cégeknek',
		'levalok-a-gazrol-tcl': 'Leválok a gázról TCL',
		/* calculators */
		'napenergia-plusz-2024': 'Napenergia Plusz 2024',
	}

</script>

<style lang="scss" scoped>


	.breadcrumbs {
		display: inline-flex;
		align-items: center;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-left: 40px;
		margin-top: 20px;
		color: var(--main-color);
		width: calc(100% - 80px);
		overflow: hidden;

		a {
			font-weight: 500;
			display: inline-block;
			display: flex;
			color: inherit;
			text-transform: capitalize;
			/* overflow: hidden;
			text-overflow: ellipsis; */
			white-space: nowrap;

			&:not(:first-child):before {
				display: inline-block;
				padding: 0 8px;
				color: #1d2b30;
				content: "/";
				pointer-events: none;
			}
		}

		svg {
			color: inherit;
			vertical-align: -4px;
		}

		@media screen and (max-width: 460px){
			margin-left: 25px;
			width: calc(100% - 50px);
			margin-top: 10px;
		}
	}

</style>