import { createRouter, createWebHistory } from 'vue-router'
import { storeToRefs } from 'pinia';
import { useProductsStore, useTextsStore, useGlobalStore } from './stores';

let mySavedPosition = 0;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/termekek/:category?',
    name: 'Products',
    component: () => import('@/views/Products.vue'),
    props: route => ({ categoryName: route.params.category || "osszes" }),
    beforeEnter(to) {
      document.title = `⚡️Energia Mindenkinek | Termékek | ${to.params.category || 'Összes'}`;
    }
  },
	{
    path: '/termekek/:category?/:uid/:slug',
    name: 'ProductDetails',
    alias: '/termekek/:uid/:slug',
    component: () => import('@/components/ProductDetails.vue'),
    props: route => ({ uid: route.params.uid }),
    /* beforeEnter(to, from){
      const exists = sourceData.destinations.find(
        destination => destination.id === parseInt(to.params.id)
      )
      if(!exists) return {
        name: 'NotFound',
        // allows keeping the URL while rendering a different page
        params: { pathMatch: to.path.split('/').slice(1) },
        query: to.query,
        hash: to.hash,
      }
    }, */
    /* children:[
      {
        path: ':experienceSlug',
        name: 'experience.show',
        component: () => import('@/views/ExperienceShow.vue'),
        props: route=> ({...route.params, id: parseInt(route.params.id)})
      }
    ] */
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/components/OrderPage.vue'),
    props: true,
  },
  {
    path: '/erdeklodes', 
    name: 'Érdeklődés',
    alias: '/visszahivas',
    component: () => import('@/components/OrderPage.vue'),
    props: true,
  },
  {
    path: '/adatkezelesi-tajekoztato', 
    name: 'Adatkezelesi',
    component: () => import('@/views/Adatkezelesi.vue'),
  },
  {
    path: '/blog', 
    name: 'Blog',
    component: () => import('@/views/Blog.vue'),
  },
  {
    path: '/blog/:postId/:postSlug?',
    name: 'BlogPost',
    component: () => import('@/components/BlogPost.vue'),
  },
  {
    path: '/kalkulatorok/:calculator?',
    name: 'Kalkulatorok',
    component: () => import('@/views/Kalkulatorok.vue'),
    /* component: () => {
      return import('@/kalkulatorok/napenergia-plusz-2024/src/napenergia-plusz-2024.vue')
    }, */
  },
  {
    path: '/eddigi-munkaink', 
    name: 'Works',
    component: () => import('@/views/Works.vue'),
  },
  {
    path: '/kapcsolat', 
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
  },
  {
    path: '/rolunk', 
    name: 'AboutUs',
    component: () => import('@/views/AboutUs.vue'),
  },
  {
    path: '/ajanlatot-kerek', 
    name: 'AjanlatotKerek',
    component: () => import('@/views/AjanlatotKerek.vue'),
  },
  {
    path: '/of-2024-elominosito', 
    alias: '/OF-2024-elominosito', 
    name: 'OF2024',
    component: () => import('@/views/of-24-elominosito.vue'),
    
  },
  {
    path: '/megrendeles', 
    name: 'Megrendeles',
    component: () => import('@/views/Megrendeles.vue'),
    
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  }
  /*
  {
    path: '/protected',
    name: 'protected',
    components:{
      default: ()=> import('@/views/Protected.vue'),
      LeftSidebar: ()=> import('@/components/LeftSidebar.vue')
    },
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/Login.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    components:{
      default: ()=> import('@/views/Invoices.vue'),
      LeftSidebar: ()=> import('@/components/LeftSidebar.vue'),
    },
    meta:{
      requiresAuth: true,
    }
  },
  {
    path: "/example/:id(\\d+)?",
    component: () => import("@/views/Login.vue")
  } */
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (
      ["#kapcsolat-vh", "#kapcsolat-uzenet", "#kapcsolat", "#ajanlatot-kerek"].includes(to.hash) ||
      ["#kapcsolat-vh", "#kapcsolat-uzenet", "#kapcsolat", "#ajanlatot-kerek"].includes(from.hash)
    ) return false;

    let newSavedPosition = savedPosition;
    if (from.path === "/") {
      newSavedPosition = mySavedPosition = null;
    }
    return newSavedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: mySavedPosition }), 650)
    })
  },
})

router.beforeEach(async (to, from) => {
  /* const { setPageLoading } = useGlobalStore();
  setPageLoading(true); */

  const { getCategories, productCategories } = useProductsStore();
  if (!productCategories.length)
    await getCategories();

  const { getPageTexts, texts } = useTextsStore();
  if (Object.keys(texts).length <= 1)
    await getPageTexts();
  
  if (from.path === '/') {
    mySavedPosition = window.scrollY;
  }

  const globalStore = useGlobalStore();
  const { modalOpened } = storeToRefs(globalStore);

  if (to.hash === "#kapcsolat-vh" || to.hash === "#kapcsolat-uzenet" || to.hash === "#kapcsolat" || to.hash === "#ajanlatot-kerek") {
    document.getElementById("app").classList.add("modal");
    modalOpened.value = to.hash.replace(/\#kapcsolat\-|\#/g, "");
    /* history.replaceState(null, null, ' '); */
    /* to.hash = ""; */
    history.replaceState(history.state, null, ' ');
  }

  const { getInfoPages } = globalStore;
  await getInfoPages();
  const { infoPages } = storeToRefs(globalStore);
  if (router.hasRoute(infoPages.value[0].link) === false) {
    for (const i in infoPages.value) {
      const page = infoPages.value[i];
      router.addRoute({
        name: page.link,
        path: `/${page.link}`,
        component: () => import(`./views/InfoPages.vue`),
        props: { page }
      });
    }
    return to.fullPath;
  }
})
router.afterEach((from, to) => {
  if (from.hash !== "") {
    router.push({ hash: "" });
  }
})

/* router.afterEach(async () => {
  const { setPageLoading } = useGlobalStore();
  setPageLoading(false);
}) */

export default router