<template>
	<div>
		<template v-if="!submitted">
			<div class="radio-check mt-2" v-if="mode !== 'offer'">
				<legend class="text-base font-medium text-gray-900 text-center block mb-2">
					Milyen módon szeretné felvenni velünk a kapcsolatot?
				</legend>
				<input type="radio" name="mode" id="mode_callback" value="callback" v-model="mode" />
				<label for="mode_callback" class="text-sm font-medium text-gray-700">Visszahívást kérek</label>
				<input type="radio" name="mode" id="mode_msg" value="msg" v-model="mode" />
				<label for="mode_msg" class="text-sm font-medium text-gray-700">Üzenetet írok</label>
			</div>

			<Form
				@submit="onSubmit"
				:validation-schema="schema"
				class="form"
			>
				<InputField
					name="name"
					label="Teljes név"
					placeholder=""
					:extra="{ autocomplete: 'name', autocapitalize: 'sentences' }"
				/>
				<InputField
					name="phone"
					type="tel"
					label="Telefonszám"
					placeholder="+36301231234"
					v-if="mode === 'callback' || mode === 'offer'"
					:extra="{ autocomplete: 'tel'}"
				/>

				<InputField
					name="email"
					label="E-mail"
					placeholder=""
					v-if="mode === 'msg' || mode === 'offer'"
					:extra="{ autocomplete: 'email', autocapitalize: 'none', autocorrect: 'off', spellcheck: 'false', inputmode: 'email'}"
				/>
				<InputField
					type="textarea"
					name="msg"
					label="Üzenet"
					placeholder="Írja ide üzenetét"
					v-if="mode === 'msg'"
				/>
				<InputField
					name="city"
					label="Helység"
					placeholder=""
					v-if="mode === 'offer'"
					:extra="{ autocomplete: 'name', autocapitalize: 'sentences' }"
				/>

				<InputField
					type="radio"
					name="tkategoria"
					label="Mi érdekli?"
					v-if="mode === 'offer'"
					:options="[
						{ label: 'Klíma' },
						{ label: 'Hűtés' },
						{ label: 'Fűtés' },
						{ label: 'Hőszivattyú' },
						{ label: 'Napelem' },
					]"
				/>

				<InputField
					:type="'checkbox'"
					name="adatkezeles"
					:value="true"
				>Elfogadom az <router-link :to="{ name: 'Adatkezelesi' }">Adatkezelési Tájékoztatót</router-link></InputField>

				<div class="buttons">
					<button 
						class="cta"
						type="submit"
						:disabled="submitted"
					>
						<template v-if="mode === 'msg'">Üzenet elküldése</template>
						<template v-else-if="mode === 'callback'">Visszahívás kérése</template>
						<template v-else-if="mode === 'offer'">Ajánlatkérés küldése</template>
					</button>
				</div>

			</Form>
		</template>
		<div 
			v-else-if="submitted"
			key="afterSubmit"
			class="my-10 max-w-6xl mx-auto p-0 md:p-6 text-2xl leading-7 md:leading-8 text-center"
		>
			<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
			Köszönjük érdeklődését, kollégánk hamarosan keresni fogja!
		</div>
	</div>
</template>

<script setup>
	import { useGlobalStore } from '../stores';
	import { storeToRefs } from 'pinia';
	import axios from 'axios';
	import { Form } from 'vee-validate';
	import { object, string, bool } from 'yup';

	axios.defaults.baseURL = import.meta.env.VITE_API_URL;

	const props = defineProps({
		mode: {
			type: String,
		}
	});

	const { modalOpened } = storeToRefs(useGlobalStore());
	let mode = ref(null);
	if (props.mode) {
		mode.value = props.mode;
	} 
	else {
		switch (modalOpened.value) {
			case "vh":
			case "kapcsolat":
			case false:
				mode.value = "callback";
				break;
			case "ajanlatot-kerek":
				mode.value = "offer";
				break;
			default:
				mode.value = "msg";
				break;
		}
	}
	/* const mode = ref(
		modalOpened.value === "vh" || modalOpened.value === "kapcsolat" || !modalOpened.value ? "callback" : "msg"
	); */

	const submitted = ref(false);
	const errMsg = {
		required: "Kötelezően kitöltendő mező.",
		email: "Kérjük ellenőrizze az email címét.",
		min: "A ${label}-nak minimum ${min} karakternek kell lennie.",
		max: "A ${label}-nak maximum ${max} karakternek kell lennie.",
	}
	const schema = object().shape({
		name: string().required(errMsg.required).label("Teljes név"),
		email: string()
			.when(([], schema) => {
				return ["msg", "offer"].includes(mode.value) ? schema.required(errMsg.required) : schema
			})
			.email(errMsg.email).label("Email"),
		phone: string()
			.when(([], schema) => {
				return mode.value !== "msg" ? schema.required(errMsg.required) : schema
			})
			.matches(/(^(?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/, "Nem megfelelő formátum")
			.min(8, errMsg.min)
			.max(14, errMsg.max)
			.label("Telefonszám"),
		msg: string()
			.when(([], schema) => {
				return mode.value === "msg" ? schema.required(errMsg.required) : schema
			}).label("Üzenet"),
		city: string()
			.when(([], schema) => {
				return mode.value === "offer" ? schema.required(errMsg.required) : schema
			}).label("Helység"),
		tkategoria: string()
			.when(([], schema) => {
				return mode.value === "offer" ? schema.required(errMsg.required) : schema
			}).label("Mi érdekli?"),
		/* street: string().required(errMsg.required).min(5, errMsg.min).label(("Cím")), */
		adatkezeles: bool().oneOf([true], errMsg.required).label("Adatkezelés elfogadása"),
	});

	const onSubmit = async (values) => {
		await axios({
			method: "POST",
			url: "https://palyazatmindenkinek.hu/_/common_form_handler.php",
			/* url: `https://${location.hostname === "localhost" ? "tesztelek." : "" }palyazatmindenkinek.hu/_/common_form_handler.php`, */
			data: {
				form: {
					"fullname": values.name,
					"phone": values?.phone ? values.phone : "",
					"email": values?.email ? values.email : "",
					"comment": values?.msg ? values.msg : "",
					"city": values?.city ? values.city : "",
					"tkategoria": 
						!values?.tkategoria ? "" : mode.value === "offer" ? `${values.tkategoria} ajánlatok` : values.tkategoria,
					/* 'irszam': values.zip, */
					/* "cim": values.street, */
					source_id: "47",
					u: "1",
				},
				type: mode.value
			}
		});
		submitted.value = true;
		setTimeout(() => {
			submitted.value = false;
		}, 6000);
	}

</script>

<style lang="scss" scoped>

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		margin: 10px auto;
		max-width: 900px;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity ease-in 0.6s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.cta.back {
		margin: 15px auto;
	}
	.buttons {
		display: flex;
		align-items: center;
		gap: 100px;
	}

	input[type = 'checkbox'] {
		border: 1px solid var(--main-color);
	}

	.checkmark {
		margin: 0 auto 40px auto;
		width: 56px;
		height: 56px;
		border-radius: 50%;
		display: block;
		stroke-width: 2;
		stroke: #fff;
		stroke-miterlimit: 10;
		box-shadow: inset 0px 0px 0px #14cc11;
		animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
	}

	.checkmark__circle {
		stroke-dasharray: 166;
		stroke-dashoffset: 166;
		stroke-width: 2;
		stroke-miterlimit: 10;
		stroke: #14cc11;
		fill: none;
		animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}

	.checkmark__check {
		transform-origin: 50% 50%;
		stroke-dasharray: 48;
		stroke-dashoffset: 48;
		animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}

	@keyframes stroke {
		100% {
			stroke-dashoffset: 0;
		}
	}
	@keyframes scale {
		0%, 100% {
			transform: none;
		}
		50% {
			transform: scale3d(1.1, 1.1, 1);
		}
	}
	@keyframes fill {
		100% {
			box-shadow: inset 0px 0px 0px 30px #14cc11;
		}
	}

	.radio-check {
		display: flex;
		flex-flow: row wrap;
		gap: 0.4rem 1.2rem;
		max-width: 440px;
		margin: 30px auto 0 auto;

		label {
			flex: 1;
			margin:0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: transparent;
			border: 1px solid var(--main-color);
			padding: 10px;
			margin-bottom: 1rem;
			text-align: center;
			box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
			position: relative;
			transition: 0.3s;
			font-size: 15px;
			border-radius: 10px;
		}
	}

	input[type="radio"] {
		display: none;
	}
	input[type="radio"] ~ label {
		cursor: pointer;
		line-height: 1rem;
		min-width: 100px;
	}
	input[type="radio"]:checked + label,
	input[type="checkbox2"]:checked + label {
		background: rgb(var(--main-color-rgb) / 85%);
		color: white;
		box-shadow: 0px 0px -10px 10px rgba(247, 144, 70, 0.5);
	}

</style>