<template>
	<Header />
	<main id="content">
		<BreadCrumbs />
		<RouterView v-slot="{Component}">
			<template v-if="Component">
				<Transition name="fade" mode="out-in">
					<KeepAlive exclude="Modal">
						<component :is="Component" :key="$route.path" :class="['pages', $route.name.toLowerCase()]"/>
					</KeepAlive>
				</Transition>
				<div class="page-loading" v-if="isPageLoading">
				<i-nonicons-loading-16/>
			</div>
			</template>
		</RouterView>
	</main>

	<footer>
		<div class="links">
			<router-link :to="{ name: 'Blog' }">Blog</router-link>
			<router-link :to="{ name: 'Products' }">Termékeink</router-link>
			<router-link :to="{ name: 'Works' }">Referenciák</router-link>
			<router-link :to="{ name: 'Adatkezelesi' }">Adatkezelési Tájékoztató</router-link>
		</div>
		<img src="@/assets/em_logo_black.svg" alt="energia mindenkinek logó">
		<div class="contact">
			<h3>Kapcsolat</h3>
			<p>Hívjon minket <br> <a href="tel:+36704500344">+36 70 450 0344</a></p>
			<span>vagy</span>
			<div class="buttons">
				<a href="#kapcsolat-vh" class="btn cta cta-sm">Kérjen visszahívást</a>
				<a href="#kapcsolat-uzenet" class="btn cta cta-sm">Írjon üzenetet</a>
			</div>
		</div>
		<div class="bottom-2 text-xs legal">&copy; {{ new Date().getFullYear() }} Energia Mindenkinek | Minden jog fenntartva.</div>
	</footer>

	<Teleport to="body">
		<Modal>
			<template #body>
				<Contact />
			</template>
		</Modal>
	</Teleport>

	<component
		:is="devWidgetLoad"
		:active="devWidgetActive"
		@toggle="(val) => devWidgetActive = val"
		ref="devWidgetOutside"
		v-if="false"
	/>
	
</template>

<script setup>
	import { onClickOutside } from '@vueuse/core';
	import { useGlobalStore } from './stores';
	import { storeToRefs } from 'pinia';
	import Contact from './views/Contact.vue';
	import { useSeoMeta } from '@unhead/vue';

	const { isPageLoading } = storeToRefs(useGlobalStore());
	/* import { useRoute } from 'vue-router';

	const route = useRoute(); */

	const devWidgetOutside = ref(null);
	const devWidgetActive = ref(false);
	onClickOutside(devWidgetOutside, () => devWidgetActive.value = false);

	let devWidgetLoad = false;
	if (["development", "vercel"].includes(import.meta.env.MODE)) {
		devWidgetLoad = defineAsyncComponent(() => import('./components/DevWidget.vue'));
	}

	useSeoMeta({
		title: '⚡️Energia Mindenkinek | Minden ami Energia',
		description: '⚡️Energia Mindenkinek | Minden ami Energia',
		ogDescription: 'Minden ami Energia',
		ogTitle: '⚡️Energia Mindenkinek | Minden ami Energia',
		ogImage: 'https://www.energiamindenkinek.hu/assets/natalya-letunova-XwrPo8MWUGQ-unsplash-Kf2_ehxq.webp',
		twitterCard: 'summary_large_image',
	})

</script>

<style lang="scss">
	:root {
		--main-color: #F16334;
		--main-color-rgb: 241 99 52;
		--main-text-color: #333;
		--main-text-color-light: #f2f2f2;
		--bg-color-dark: #1f1f1e;
	}
	@media screen and (min-width: 460px){
		:root {
			--header-height: 70px;
		}
	}
	@media screen and (max-width: 460px){
		:root {
			--header-height: 60px;
		}
	}

	$header-height: var(--header-height);

	.page-loading {
		animation:rotate 5s infinite;
		position: absolute;
		inset: 0;
		margin: 33% auto 0 auto;
		margin: auto;
		width: 28px;
		height: 28px;
		pointer-events: none;
		animation-timing-function: cubic-bezier(1,.5,.5,1);
		svg {
			width: 28px;
			height: 28px;
			color: var(--main-color);
		}
	}

	* {
		position: relative;
		box-sizing: border-box;
	}
	html {
		font-family: "Satoshi";
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		background-color: #1f1f1e;
		/* background-color: #fdfdfd; */
		scroll-behavior: smooth;
		color: #333;
	}
	body {
		background-color: #fdfdfd;
		margin: 0;
		padding: 0;
		/* should use --header-height variable */
		margin-top: calc($header-height * -1) !important;
    	padding-top: $header-height;
	}
	button,
	input,
	select,
	textarea {
		font-family: "Satoshi";

		&:focus {
			--un-ring-color: rgb(var(--main-color-rgb) / 60%) !important;
			border-color: transparent !important;
		}
	}
	textarea {
		padding-top: 5px;
		resize: vertical;
	}
	h1, h2, h3, h4, h5, h6 {
		font-family: "Signika";
	}
	h3, h4, h5 {
		margin: 20px 0 30px 0;
	}
	button,
	a.btn {
		border: none;
		background: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px 0;
		/* overflow: hidden; */
		cursor: pointer;

		&.cta {
			background-color: #F16334;
			color: #fff;
			font-size: 14px;
			font-weight: 500;
			margin: 5px;
			padding: 10px 15px;
			text-align: center;
			border-radius: 5px;
			text-transform: uppercase;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);

			&:hover {
				transform: scale(1.015);
				text-shadow: 0 2px 2px rgba(0,0,0, 0.1);
				box-shadow: rgba(242, 99, 51, 0.25) 0px 6px 12px -2px, rgba(242, 99, 51, 0.3) 0px 3px 7px -3px;
				box-shadow: rgba(242, 99, 51, 0.5) 0px 2px 4px,
								rgba(242, 99, 51, 0.4) 0px 7px 13px -3px,
								rgba(242, 99, 51, 0.3) 0px -3px 0px inset;
			}

			&-lg {
				font-size: 16px;
			}
			&-sm {
				font-size: 12px;
				padding: 7px 15px;
			}

			&.shine {
				&:after {
					content: "";
					background: #fff;
					height: 155px;
					left: -75px;
					opacity: 0.2;
					position: absolute;
					top: -50px;
					transform: rotate(35deg);
					width: 50px;
					z-index: 10;
					pointer-events: none;
				}
				&:hover {
					&:after {
						left: 120%;
					}
				}
			}
		}

		svg {
			font-size: 17px;
			pointer-events: none;
		}
	}
	a {
		color: #2a2a2a;
		text-decoration: none;
		cursor: pointer;
	}

	/* :deep(input:placeholder),
	:deep(input::placeholder) {
		color: red !important;
	} */
	[style="color: rgb(230, 126, 35);"] {
		color: var(--main-color) !important;
	}

	#app header a.router-link-active {
		color: var(--main-color);
		&.cta {
			color: #fff;
		}
	}

	#app {
		overflow: hidden;

		&.modal {
			overflow: hidden;

			#content {
				opacity: 0.2;
				filter: blur(5px);
				overflow: hidden;
				pointer-events: none;
			}
		}

		#content {
			min-height: 76vh;
			margin-top: 70px;
			display: flex;
			flex-direction: column;
    		/* align-items: center; */
			width: 100%;
			transition: 0.3s;
			/* padding: 0 40px; */

			.page {
				width: 100%;
			}
			.pages {
				padding-left: 40px;
				padding-right: 40px;

				&.home {
					padding: 0;
				}

				@media screen and (max-width: 460px) {
					padding: 0 25px 30px 25px;
				}
			}
		}

		section:not(.carousel) {
			width: 100%;
			max-width: 1400px;
			padding: 20px 40px 40px;
			margin: 0 auto;

			&.full {
				padding: 0;
				max-width: unset;
			}

			& > h2 {
				text-align: center;
				color: var(--main-color);
				font-family: 'Signika';
				font-weight: 400;
				font-size: 30px;
			}
		}

		.texts {
			margin: 0px 0 0 0;
			gap: 20px;

			.text {
				padding: 10px 30px;
				max-width: 900px;
    			margin: auto;
				font-size: 17px;
				border: 1px solid var(--main-color);
				border-radius: 12px;
				box-sizing: content-box;
				background-color: rgb(var(--main-color-rgb) / 4%);

				@media (max-width: 730px) {
					padding: 20px;
					font-size: 15px;
					column-count: unset;
					column-gap: unset;
				}
			}

			@media (max-width: 730px) {
				padding: 0;
				margin-top: 30px;
			}
		}

		/* #content {
			width: 100%;
			max-width: 1150px;
			padding: 0 50px;
			margin: 50px auto;
		}
		#content {
			min-height: 70vh;
			@media (max-width: 460px) {
				padding: 0 30px;
			}
		} */

		footer {
			background-color: #3e3c3d;
			background-color: #373a3a;
			color: #fff;
			padding: 60px 60px 0;
			overflow: hidden;
			display: flex;
			flex-wrap: wrap;
    		gap: 50px;
			justify-content: space-around;
			align-items: center;
			display: grid;
			grid-template-columns: repeat(3, minmax(240px, 1fr));
			justify-content: center;
			justify-items: center;

			img {
				width: 300px;
			}

			& > * {
				order: 0;
			}

			.links {
				display: flex;
				flex-direction: column;
				gap: 5px 0;
			}
			.legal {
				grid-column: 1 / -1;
    			order: 1;
			}

			@media (max-width: 660px) {
				padding: 50px 30px;

				/* .legal {
					grid-column: unset;
				} */

				img {
					width: 240px;
				}
			}

			a:not(.cta) {
				color: #eee;
				transition: color 0.3s;
				font-size: 15px;

				&:hover {
					color: var(--main-color);
				}
			}

			.contact {
				display: flex;
				flex-direction: column;
				align-items: center;
				flex-wrap: nowrap;
				padding: 15px 50px;
				background: rgb(var(--main-color-rgb) / 10%);
				border-radius: 20px;
				border: 1px solid #333;
				max-width: 320px;

				h3 {
					/* align-self: flex-start; */
					margin-top: 0;
					margin-bottom: 0px;
				}
				a:not(.cta) {
					color: var(--main-color);
					&:hover {
						text-decoration: underline;
					}
				}
				p {
					text-align: center;
					margin-bottom: 0;
				}

				span {
					font-size: 12px;
					font-style: italic;
					color: #aaa;
					margin: 10px 0;
				}
				a.cta {
					display: block;
				}
				a.cta:nth-of-type(2) {
					margin-top: 10px;
				}

			}

			.footer-content {
				width: 100%;
				max-width: 1200px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 30px 0;

				@media (max-width: 460px) {
					justify-content: space-around;
				}
			}

			@media (max-width: 460px) {
				padding: 30px 20px 0px 20px;
			}
			@media (max-width: 940px) {
				grid-template-columns: 1fr;
				img {
					order: 1;
				}
				.links {
					align-items: center;
				}
			}

			.logo {
				width: 100%;
				max-width: 300px;
			}
		}
	}	

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.7s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
	@keyframes rotate {
		from { -webkit-transform: rotate(0deg) } 
		to { -webkit-transform: rotate(360deg) } 
	}

</style>
