<template>
	<header :class="{scrolled: ((!(isHome && !scrolled)) || !isHome)}">
		<nav :class="{active: opened}">
			<a class="logo" href="/">
				<img v-show="(isHome && !scrolled)" src="@/assets/em_logo_black.svg" alt="energia mindenkinek logó">
				<img v-show="!(isHome && !scrolled)" src="@/assets/em_logo.svg" alt="energia mindenkinek logó">
			</a>
			<div class="menu-links">
				<router-link :to="{ name: 'Products' }" class="menu-link">Termékek</router-link>
				<router-link :to="{ name: 'Works' }" class="menu-link">Eddigi Munkáink</router-link>
				<!-- <a href="#" class="menu-link">Rólunk</a> -->
				<router-link :to="{ name: 'Contact' }" class="menu-link">Kapcsolat</router-link>
				<span class="separator">|</span>
				<router-link :to="{ name: 'Blog' }" class="menu-link">Blog</router-link>
				<router-link :to="{ name: 'AboutUs' }" class="menu-link">Rólunk</router-link>
				<!-- <router-link :to="{ name: 'Kalkulatorok' }" class="menu-link">Kalkulátorok</router-link> -->
				<router-link :to="{ name: 'AjanlatotKerek' }" class="menu-link btn cta cta-sm">Ajánlatot kérek</router-link>
				<!-- <a href="#" class="menu-link">Pályázatok</a> -->
			</div>
			<div class="menu-buttons">
				<a target="_blank" class="fb" href="https://www.facebook.com/energiamindenkinek">
					<i-logos-facebook/>
				</a>
				<button v-if="false" class="search">
					<i-carbon-search/>
					<!-- <i-line-md:search/> -->
					Keresés
				</button>
				<button v-if="false" class="login">
					<i-carbon-user/>
					<!-- <i-line-md:account-small/> -->
					login
				</button>
				<button v-if="false" class="cart">
					<i-carbon-shopping-cart/>
					Kosár
				</button>
				<button class="mobile-menu" @click="toggleMobileMenu">
					<span class="mobile-menu-btn" ref="mobileMenuBtnRef"></span>
					<!-- <i-carbon-menu/> -->Menü
				</button>
			</div>
		</nav>

	</header>
</template>

<script setup>
	import { useWindowScroll, useScrollLock } from '@vueuse/core';
	import { useRoute } from 'vue-router';

	const route = useRoute();

	const body = ref(document.body);
	const bodyScroll = useScrollLock(body);
	const isHome = ref(route.name === 'Home');
	const mobileMenuBtnRef = ref(null)
	console.info('route', route);

	watch([() => route.path, () => route.hash, () => route.fullPath], (newValue) => {
		console.info('route changed', route);
		isHome.value = route.name === 'Home';
		opened.value = bodyScroll.value = false;
		mobileMenuBtnRef.value.classList.toggle('is-active', false);
	})

	const scrolled = ref(false);
	const opened = ref(false);

	const toggleMobileMenu = (e) => {
		mobileMenuBtnRef.value.classList.toggle('is-active', !opened.value);
		opened.value = bodyScroll.value = !opened.value;
	}

	const { y } = useWindowScroll();
	watch(y, (newV) => {
		scrolled.value = newV > 50;
	});

</script>

<style lang="scss" scoped>
	:deep(a.fb) {
		margin-right: 5px;
		svg path:first-of-type {
			transition: 0.3s;
		}
		&:hover {
			svg path:first-of-type {
				fill: var(--main-color);
			}
		}
	}

	$header-height: var(--header-height);

	$menu-switch: 965px;

	header {
		width: 100%;
		height: $header-height;
		padding: 8px 10px;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		position: fixed;
		top: 0;
		z-index: 2;
		transition: 0.6s;

		background-color: #fff;
		background-color: #fff;
		border-bottom: 1px solid #ececec;
		box-shadow: 0 0 8px rgba(100,100,100, 0.1);

		background-color: transparent;
		border-bottom: 1px solid #fdfdfd21;

		&::after {
			content: " ";
			position: absolute;
			inset: 0;
			margin: auto;
			pointer-events: none;
			z-index: 0;
			background-color: #ffffff00;
			backdrop-filter: blur(10px);
			backdrop-filter: blur(14px);
			/* for safari */
			-webkit-backdrop-filter: blur(10px) !important;
			-webkit-backdrop-filter: blur(14px) !important;
		}
		&.scrolled {
			background-color: rgba(255, 255, 255, 0.8);
			background-color: rgba(255, 255, 255, 0.65);
			/* backdrop-filter: blur(10px); */
			a, button {
				color: #2a2a2a;
			}
		}

		a {
			text-decoration: none;
			
			&.logo {
				font-size: 32px;
				margin-right: 40px;
				margin-right: 20px;
				display: flex;

				img {
					width: 260px;
					width: 220px;
					margin-top: 4px;
				}
			}
		}

		nav {
			width: 100%;
			display: flex;
			flex-wrap: nowrap;
			font-size: clamp(0.8rem, 1.1vw, 0.85rem);
			color: rgba(237, 237, 237, 90%);
			z-index: 1;

			a, button {
				cursor: pointer;
				transition: color 0.2s;
				color: inherit;

				&:hover {
					color: var(--main-color);
				}
			}

			.menu-buttons {
				width: 100%;
				display: flex;
				flex-wrap: nowrap;
				justify-content: flex-end;
				align-items: center;
				gap: 0 15px;

				a {
					display: inline-flex;
					svg {
						width: 24px;
						height: 24px;
					}
				}
			}

			.menu-links {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				gap: 0 15px;
				justify-content: center;
				white-space: nowrap;

				.menu-link {
					padding: 8px 3px;
					text-transform: uppercase;
					/* font-size: 14px; */
					font-size: inherit;
					font-weight: 500;

					&.cta {
						color: #fff;
						padding: 8px 10px;
						padding: 7px 10px;
						font-size: 12px;
					}
				}

				.separator {
					color: var(--main-color);
					margin-right: -5px;
    				margin-left: -5px;
					margin: -5px;
				}

				&.mobile {
					justify-content: center;
					white-space: nowrap;
					background-color: rgba(255, 255, 255, 0.7);
					backdrop-filter: blur(10px) !important;
					-webkit-backdrop-filter: blur(10px) !important;
					width: 100vw;
					inset: 0;
					height: 100vh;
					flex-direction: column;
					z-index: 10;
					transform: translateX(100%);
				}
			}
			.mobile-menu {
				display: none;

				/* svg {
					font-size: 26px;
					font-size: 17px;
				} */
			}

			@media (max-width: $menu-switch) {
				.mobile-menu {
					display: flex;
					justify-content: space-between;
					height: 41px;
					margin-top: 3px;

					&:hover .mobile-menu-btn {
						color: var(--main-color);
					}
				}
				.menu-links {
					height: calc(100vh - $header-height);
					margin-top: $header-height;
					height: calc(100vh - 82px);
    				margin-top: 74px;
					position: absolute;
					transform: translateX(100vw);
					justify-content: center;
					white-space: nowrap;
					inset: 0;
					flex-direction: column;
					z-index: 10;
					background-color: rgba(255, 255, 255, 0.7);
					backdrop-filter: blur(10px) !important;
					-webkit-backdrop-filter: blur(10px) !important;

					.separator {
						transform: rotate(90deg);
						margin: -10px;
					}
				}
				& > * {
					transition: 0.6s;
				}

				&.active {

					.logo {
						margin: 0;
					}

					.menu-links {
						pointer-events: all;
						transform: translateX(0%);
						color: #2a2a2a;
						font-size: 24px;
						gap: 10px;
					}
				}
			}
		}
		@media (max-width: 500px) {
			nav .menu-buttons {
				gap: 0 10px;
			}
			a.logo {
				margin-right: 0;

				img {
					width: 220px;
				}
			}
		}
		@media (max-width: 400px) {
			nav .menu-buttons {
				gap: 0 10px;
			}
			a.logo {
				margin-right: 0;

				img {
					width: 180px;
				}
			}
		}
	}

	$buttonWidth: 18px;
	$buttonHeight: 14px;
	$buttonColor: #6a6a6a;
	$buttonColorAlt: #c7ccd0;
	$lineThickness: 2px;
	$transitionSpeed: .2s;
	$transitionEasing: ease-in-out;

	.mobile-menu-btn {
		position: relative;
		display: block;
		width: $buttonWidth;
		height: $buttonHeight;
		margin-top: 2px;
		
		background: transparent;
		border-top: $lineThickness solid;
		border-bottom: $lineThickness solid;
		pointer-events: none;
		
		color: $buttonColorAlt;
		
		transition: color $transitionSpeed;

		&.is-active {
			border-color: transparent;

			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}

			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}

		&:before,
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: $lineThickness;
			
			position: absolute;
			top: 50%;
			left: 50%;
			
			background: currentColor;
			
			transform: translate(-50%, -50%);
			transition: transform $transitionSpeed $transitionEasing;
		}
	}

	.scrolled .mobile-menu-btn {
		color: $buttonColor;
	}

</style>