import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue';
/* import * as Sentry from "@sentry/vue" */

/* import { inject as vercelAnalyticsInject } from '@vercel/analytics';
 
vercelAnalyticsInject(); */

const app = createApp(App);

/* Sentry.init({
  app,
  dsn: "https://d6669201fc2a8c6ee90827274af047e5@o4505676089589760.ingest.sentry.io/4505676091359232",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["palyazatmindenkinek.hu", "vercel.app"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        /.*121721\/[0-9]{1,3}\//,
      ],
    }),
  ],
  enabled: import.meta.env.MODE !== 'development',
  debug: false,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

const pinia = createPinia();
const head = createHead();

import 'virtual:uno.css';

app
  .use(router)
	.use(pinia)
	.use(head)
	.mount("#app");	
