<template>
	<Transition name="modal">
		<div class="modal" v-if="modalOpened">
			<span class="close" @click="closeModal">&times;</span>
			<div class="modal-header">
				<slot name="header"></slot>
			</div>

			<div class="modal-body">
				<slot name="body"/>
			</div>
		</div>
	</Transition>
</template>

<script setup>
	import { storeToRefs } from 'pinia';
	import { useGlobalStore } from '../stores';
	import { useScrollLock } from '@vueuse/core';

	const { modalOpened } = storeToRefs(useGlobalStore());

	const body = ref(document.body);
	const bodyScroll = useScrollLock(body);

	watch(modalOpened, (newValue, oldValue) => {
		if (newValue) {
			bodyScroll.value = true;
		}
		else {
			bodyScroll.value = false;
		}
	})

	const closeModal = () => {
		modalOpened.value = false;
		document.getElementById("app").classList.remove("modal");
	}

</script>

<style lang="scss" scoped>

	$header-height: var(--header-height);

	.modal {
		position: fixed;
		z-index: 1;
		left: 0;
		right: 0;
		margin: auto;
		top: calc($header-height + 1.5%);
		width: 100%;
    	max-width: 550px;
		height: auto;
		max-height: 85vh;		
		overflow: auto;
		padding: 30px;
		border-radius: 6px;
		background-color: #fff;
		box-shadow: 0 6px 13px 3px rgba(50, 50, 50, 0.2);
		transition: opacity 0.3s ease;

		@media screen and (max-width: 460px){
			top: 70px;
			max-height: calc(100dvh - 75px);
    		overflow: auto;
		}

		.close {
			position: absolute;
			top: 0px;
			right: 10px;
			color: var(--main-color);
			font-size: 28px;
			font-weight: bold;
			cursor: pointer;
		}
	}

	.modal-enter-from {
		opacity: 0;
	}

	.modal-leave-to {
		opacity: 0;
	}

	.modal-enter-from .modal-container,
	.modal-leave-to .modal-container {
		transform: scale(1.1);
	}

</style>